// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeTelegramCard_r0Y7v{background-color:#161119;display:block;min-height:28.4rem;padding:4.6rem 3.6rem;position:relative;transition:transform .3s ease,color .3s ease;-webkit-user-select:none;user-select:none}@media(max-width:1279.98px){.HomeTelegramCard_r0Y7v{align-items:center;display:flex;min-height:10.8rem;padding:2.4rem 2rem}}.HomeTelegramCard_r0Y7v:hover{transform:scale(1.02)}.HomeTelegramCard_r0Y7v:hover .title_UUbqj span,.button_mrOkE{color:#fff}.button_mrOkE{background-color:#804dff;margin-bottom:3.6rem}.button_mrOkE.v-circle-button{cursor:default}@media(max-width:1279.98px){.button_mrOkE{margin-bottom:0;margin-right:2.4rem}}.title_UUbqj{font-family:\"Bebas-Neue\",sans-serif;font-size:3.6rem;font-weight:700;letter-spacing:-.072rem;line-height:1}@media(max-width:1279.98px){.title_UUbqj{font-size:2rem;font-size:1.8rem;letter-spacing:-.04rem;line-height:1}}.title_UUbqj span{display:block}.title_UUbqj span:last-child{color:#848286;transition:color .3s ease}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeTelegramCard": "HomeTelegramCard_r0Y7v",
	"title": "title_UUbqj",
	"button": "button_mrOkE"
};
module.exports = ___CSS_LOADER_EXPORT___;
